import { Stepper, Step, StepLabel, StepConnector, StepIconProps } from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';
import { inject, observer } from 'mobx-react';
import React from 'react';

import { ICreateCSRProps } from 'components/certificate-management/types';

const useStyles = makeStyles(() => ({
    stepperHorizontal: {
        backgroundColor: 'var(--ghost-white)',
        padding: 0,
        marginTop: '30px',
    },
    stepHorizontal: {
        padding: 0,
    },
    labelContainer: {
        '& .MuiStepLabel-labelContainer': {
            display: 'none',
        },
        '& .MuiStepLabel-iconContainer': {
            padding: 0,
        },
    },
    connector: {
        border: '1px solid var(--spun-pearl)',
        '&.MuiStepConnector-active': {
            borderColor: 'var(--primary)',
            '& .MuiStepConnector-lineHorizontal': {
                borderColor: 'var(--primary)',
            },
        },
        '&.MuiStepConnector-completed': {
            borderColor: 'var(--primary)',
            '& .MuiStepConnector-lineHorizontal': {
                borderColor: 'var(--primary)',
            },
        },
    },
    connectorLine: {
        borderColor: 'var(--spun-pearl)',
    },
}));

const useCustomStepStyles = makeStyles(() => ({
    root: {
        width: '35px',
        height: '35px',
        borderRadius: '50%',
        backgroundColor: 'var(--ghost-white)',
        border: '2px solid var(--spun-pearl)',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        fontSize: 'var(--fs-large)',
        fontWeight: 500,
        color: 'var(--spun-pearl)',
    },
    active: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        width: '35px',
        height: '35px',
        borderRadius: '50%',
        border: '2px solid var(--primary)',
    },
    completed: {
        width: '35px',
        height: '35px',
        borderRadius: '50%',
        borderColor: 'var(--primary)',
        backgroundColor: 'var(--primary)',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        fontSize: 'var(--fs-large)',
        fontWeight: 500,
        color: 'var(--white)',
    },
    innerCircle: {
        width: '17px',
        height: '17px',
        borderRadius: '50%',
        backgroundColor: 'var(--primary)',
    },
}));

const CustomStepIcon = (props: StepIconProps) => {
    const { active, completed, icon } = props;
    const classes = useCustomStepStyles();

    const getRootStyles = (): string => {
        if (active) return `${classes.root} ${classes.active}`;
        if (completed) return `${classes.root} ${classes.completed}`;
        return classes.root;
    };

    return (
        <div className={getRootStyles()}>
            {active ? <div className={classes.innerCircle}></div> : icon}
        </div>
    );
};

const StepHandler: React.FC<Omit<ICreateCSRProps, 'AppStore'>> = ({
    CertificateManagementStore,
}) => {
    const { page } = CertificateManagementStore!;
    const classes = useStyles();
    const steps = ['chooseRegion', 'csrType', 'enterDn', 'enterAddress', 'enterDetails', 'summary'];

    return (
        <div>
            <Stepper
                activeStep={page.step - 1}
                classes={{
                    root: classes.stepperHorizontal,
                }}
                className="stepManager"
                connector={
                    <StepConnector
                        classes={{
                            root: classes.connector,
                            line: classes.connectorLine,
                        }}
                    />
                }>
                {steps.map((label) => (
                    <Step
                        classes={{
                            root: classes.stepHorizontal,
                        }}
                        key={label}>
                        <StepLabel
                            classes={{
                                root: classes.labelContainer,
                            }}
                            StepIconComponent={CustomStepIcon}>
                            {label}
                        </StepLabel>
                    </Step>
                ))}
            </Stepper>
        </div>
    );
};

export default inject('CertificateManagementStore')(observer(StepHandler));
