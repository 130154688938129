import { Checkbox, Divider, Grid, Paper } from '@material-ui/core';
import ArrowBackIosIcon from '@material-ui/icons/ArrowBackIos';
import { makeStyles } from '@material-ui/styles';
import { inject, observer } from 'mobx-react';
import React from 'react';

import AppController from 'base/App.controller';
import StepHandler from 'components/certificate-management/create-certificate/StepHandler';
import Breadcrumbs from 'components/common/breadcrumbs/Breadcrumbs';
import CustomThemeProvider from 'components/common/theme/CustomThemeProvider';
import ButtonWrapper from 'components/common/wrappers/ButtonWrapper';
import DefaultContainer from 'layout/DefaultContainer';

import { Itranslation } from 'types';
import { ICreateCSRProps } from 'components/certificate-management/types';

const useStyles = makeStyles(() => ({
    checkbox: {
        margin: '0px',
    },
    backButton: {
        color: 'var(--primary)',
        border: '1px solid var(--primary)',
        background: 'var(--ghost-white)',
        '&:hover': {
            border: '1px solid var(--primary)',
        },
        '& .MuiButton-label': {
            fontWeight: 'var(--font-weight-regular)',
        },
    },
}));

const CreateCSRStep2: React.FC<ICreateCSRProps> = ({ AppStore, CertificateManagementStore }) => {
    const { t }: Itranslation = AppController.getTranslation(AppStore.scope, [
        'create-certificate',
        'common',
    ]);
    const {
        certTypes,
        rootStore,
        updateField,
    } = CertificateManagementStore!.CreateCertificateStore;
    const classes = useStyles();

    const _handleCheck = (event: React.ChangeEvent<HTMLInputElement>) => {
        updateField(event.target.name, event.target.checked);
    };

    const _hasOptionSelected = () => {
        return Object.values(certTypes).reduce((prev, curr) => prev || curr, false);
    };

    return (
        <div className={'createCSRStep2'}>
            <DefaultContainer>
                <Breadcrumbs
                    previousStep={rootStore.previousStep}
                    parentPath={t('parentTitle')}
                    currentPath={t('title')}
                />
                <Grid container spacing={4}>
                    <Grid container item xs={8}>
                        <Grid item xs={12}>
                            <Divider />
                            <Grid container>
                                <Grid item xs={8}>
                                    <StepHandler />
                                </Grid>
                            </Grid>
                            <div className={'subtitle'}>{t('selectCsrType')}</div>
                            <Grid container spacing={4}>
                                {Object.keys(certTypes).map((key) => (
                                    <Grid item xs={6} key={key}>
                                        <Paper className={'checkboxPaper'}>
                                            <CustomThemeProvider alt>
                                                <div className={'checkboxContainer'}>
                                                    <Checkbox
                                                        classes={{
                                                            root: classes.checkbox,
                                                        }}
                                                        checked={
                                                            certTypes[key as keyof typeof certTypes]
                                                        }
                                                        onChange={_handleCheck}
                                                        name={key}
                                                    />
                                                </div>
                                                <div className={'textContainer'}>
                                                    <div className={'title'}>{t(key)}</div>
                                                    <div className={'description'}>
                                                        {t(`${key}Detail`)}
                                                    </div>
                                                </div>
                                            </CustomThemeProvider>
                                        </Paper>
                                    </Grid>
                                ))}
                            </Grid>
                        </Grid>
                        <Grid item xs={12} className={'bottomSection'}>
                            <Grid container spacing={4}>
                                <Grid item xs={2}>
                                    <ButtonWrapper
                                        startIcon={<ArrowBackIosIcon fontSize="small" />}
                                        variant="outlined"
                                        className={classes.backButton}
                                        onClick={rootStore.previousStep}>
                                        {t('common:back')}
                                    </ButtonWrapper>
                                </Grid>
                                <Grid item xs={8} />
                                <Grid item xs={2}>
                                    <ButtonWrapper
                                        onClick={rootStore.nextStep}
                                        disabled={!_hasOptionSelected()}>
                                        {t('common:next')}
                                    </ButtonWrapper>
                                </Grid>
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
            </DefaultContainer>
        </div>
    );
};

export default inject('AppStore', 'CertificateManagementStore')(observer(CreateCSRStep2));
