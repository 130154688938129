import { Divider, Grid, Paper, Typography } from '@material-ui/core';
import { inject, observer } from 'mobx-react';
import React from 'react';

import AppController from 'base/App.controller';
import StepHandler from 'components/certificate-management/create-certificate/StepHandler';
import Breadcrumbs from 'components/common/breadcrumbs/Breadcrumbs';
import ButtonWrapper from 'components/common/wrappers/ButtonWrapper';
import DefaultContainer from 'layout/DefaultContainer';

import downloadIcon from '../../../images/download.svg';

import { Itranslation } from 'types';
import { ICreateCSRProps } from 'components/certificate-management/types';

const CreateCSRStep6: React.FC<ICreateCSRProps> = ({ AppStore, CertificateManagementStore }) => {
    const { t }: Itranslation = AppController.getTranslation(AppStore.scope, [
        'create-certificate',
        'common',
    ]);
    const {
        certTypes,
        constructSummary,
        dn,
        handleDownload,
        _hasCSRDownloaded,
        hasCSRGenerated,
        handleCompleteClick,
        handleCompleteClickDn,
        loading,
        reset,
        rootStore,
    } = CertificateManagementStore!.CreateCertificateStore;

    const _handlePreviousStep = async () => {
        if (hasCSRGenerated) {
            await _handleComplete();
            return;
        }
        dn ? rootStore.jumpToStep(3) : rootStore.previousStep();
    };

    const _handleComplete = async () => {
        await rootStore.sendToLanding();
        reset();
    };

    const _handleConfirm = async () => {
        if (dn) await handleCompleteClickDn(AppStore);
        else await handleCompleteClick(AppStore);
    };

    return (
        <div className={'createCSRStep6'}>
            <DefaultContainer>
                <Breadcrumbs
                    previousStep={_handlePreviousStep}
                    parentPath={t('parentTitle')}
                    currentPath={t('title')}
                />
                <Grid container>
                    <Grid container item xs={8}>
                        <Grid item xs={12}>
                            <Divider />
                            <Grid container>
                                <Grid item xs={8}>
                                    <StepHandler />
                                </Grid>
                            </Grid>
                            <div className={'title'}>{t('summary')}</div>
                            <Grid container spacing={3}>
                                <Grid item xs={6}>
                                    {constructSummary().map((step, index) => {
                                        if (!step.value) {
                                            return <div key={step.heading}></div>;
                                        }
                                        return (
                                            <div key={step.heading}>
                                                <Paper className={'card'}>
                                                    <Grid container>
                                                        <Grid item xs={12}>
                                                            <Grid container>
                                                                <Grid item sm={6}>
                                                                    <Typography className="subtitle">
                                                                        {t(`${step.heading}`)}
                                                                    </Typography>
                                                                </Grid>
                                                                <Grid item sm={6}>
                                                                    <Grid
                                                                        container
                                                                        justify="flex-end">
                                                                        <Grid item>
                                                                            <ButtonWrapper
                                                                                className="edit"
                                                                                onClick={() =>
                                                                                    rootStore.jumpToStep(
                                                                                        index + 1,
                                                                                    )
                                                                                }
                                                                                disabled={
                                                                                    loading ||
                                                                                    hasCSRGenerated
                                                                                }
                                                                                variant="text">
                                                                                {t('common:edit')}
                                                                            </ButtonWrapper>
                                                                        </Grid>
                                                                    </Grid>
                                                                </Grid>
                                                            </Grid>
                                                        </Grid>
                                                        <Grid item xs={12}>
                                                            <Grid container>
                                                                {step.type === 'string' ? (
                                                                    <Grid item sm={6}>
                                                                        <Typography className="value">
                                                                            {step.value as string}
                                                                        </Typography>
                                                                    </Grid>
                                                                ) : (
                                                                    Object.entries(step.value).map(
                                                                        ([key, value]) => (
                                                                            <Grid
                                                                                container
                                                                                key={key}>
                                                                                <Grid item sm={6}>
                                                                                    <Typography className="value1">
                                                                                        {t(
                                                                                            `${key}`,
                                                                                        )}
                                                                                    </Typography>
                                                                                </Grid>
                                                                                <Grid item sm={6}>
                                                                                    <Grid
                                                                                        container
                                                                                        justify="flex-end">
                                                                                        <Grid item>
                                                                                            <Typography className="value2">
                                                                                                {value
                                                                                                    ? (value as string)
                                                                                                    : t(
                                                                                                          'na',
                                                                                                      )}
                                                                                            </Typography>
                                                                                        </Grid>
                                                                                    </Grid>
                                                                                </Grid>
                                                                            </Grid>
                                                                        ),
                                                                    )
                                                                )}
                                                            </Grid>
                                                        </Grid>
                                                    </Grid>
                                                </Paper>
                                            </div>
                                        );
                                    })}
                                </Grid>
                                <Grid item xs={4} container spacing={2}>
                                    <Grid container alignItems="flex-end">
                                        <Grid item xs={6}>
                                            <ButtonWrapper
                                                className="finish"
                                                disabled={loading || hasCSRGenerated}
                                                loading={loading}
                                                onClick={_handleConfirm}>
                                                {t('common:confirm')}
                                            </ButtonWrapper>
                                        </Grid>
                                    </Grid>
                                </Grid>
                                <Grid item xs={2} />
                            </Grid>
                            <Grid container>
                                <Grid item xs={8}>
                                    <Typography className="confirmInfo">
                                        {t('downloadInfo')}
                                    </Typography>
                                </Grid>
                            </Grid>
                            <Grid container className="downloadButtonContainer" spacing={3}>
                                {Object.keys(certTypes)
                                    .filter((key) => certTypes[key as keyof typeof certTypes])
                                    .map((cert) => (
                                        <Grid item xs={3} key={cert}>
                                            <ButtonWrapper
                                                className="downloadButton"
                                                startIcon={<img src={downloadIcon} />}
                                                disabled={!hasCSRGenerated}
                                                onClick={() => handleDownload(cert)}>
                                                {`${t('download')} ${t(cert)}`}
                                            </ButtonWrapper>
                                        </Grid>
                                    ))}
                            </Grid>
                            <Grid container className="completeButtonContainer" spacing={3}>
                                <Grid container item xs={6} justify="flex-end">
                                    <Grid item xs={4}>
                                        <ButtonWrapper
                                            className="completeButton"
                                            onClick={_handleComplete}
                                            disabled={!_hasCSRDownloaded}>
                                            {t('common:complete')}
                                        </ButtonWrapper>
                                    </Grid>
                                </Grid>
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
            </DefaultContainer>
        </div>
    );
};

export default inject('AppStore', 'CertificateManagementStore')(observer(CreateCSRStep6));
