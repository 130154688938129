import { Divider, Grid, MenuItem } from '@material-ui/core';
import ArrowBackIosIcon from '@material-ui/icons/ArrowBackIos';
import { makeStyles } from '@material-ui/styles';
import { inject, observer } from 'mobx-react';
import React, { useEffect } from 'react';

import AppController from 'base/App.controller';
import StepHandler from 'components/certificate-management/create-certificate/StepHandler';
import Breadcrumbs from 'components/common/breadcrumbs/Breadcrumbs';
import ButtonWrapper from 'components/common/wrappers/ButtonWrapper';
import FormWrapper from 'components/common/wrappers/FormWrapper';
import SelectWrapper from 'components/common/wrappers/SelectWrapper';
import TextFieldWrapper from 'components/common/wrappers/TextFieldWrapper';
import DefaultContainer from 'layout/DefaultContainer';

import { countries } from 'constants/countries';

import { ICreateCSRProps } from 'components/certificate-management/types';
import { ICountry, Itranslation } from 'types';

const useStyles = makeStyles(() => ({
    backButton: {
        color: 'var(--primary)',
        border: '1px solid var(--primary)',
        background: 'var(--ghost-white)',
        '&:hover': {
            border: '1px solid var(--primary)',
        },
        '& .MuiButton-label': {
            fontWeight: 'var(--font-weight-regular)',
        },
    },
}));

const CreateCSRStep4: React.FC<ICreateCSRProps> = ({ AppStore, CertificateManagementStore }) => {
    const { t }: Itranslation = AppController.getTranslation(AppStore.scope, [
        'create-certificate',
        'common',
    ]);
    const ref = React.createRef<HTMLFormElement>();
    const {
        updateAddressField,
        isAddressFormFilled,
        isCountryFilled,
        companyAddressForm,
        states,
        zipLabel,
        stateLabel,
        rootStore,
    } = CertificateManagementStore!.CreateCertificateStore;
    const classes = useStyles();

    const [ctx, setCtx] = React.useState({
        isFormValid: false,
    });

    useEffect(() => {
        const isFormValid = isAddressFormFilled && ref.current?.checkValidity();

        setCtx({ isFormValid: !!isFormValid });
    }, Object.values(companyAddressForm));

    const _handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        updateAddressField(event.target.name, event.target.value);
    };

    return (
        <div className={'createCSRStep4'}>
            <DefaultContainer>
                <Breadcrumbs
                    previousStep={rootStore.previousStep}
                    parentPath={t('parentTitle')}
                    currentPath={t('title')}
                />
                <Grid container>
                    <Grid container item xs={8}>
                        <Grid item xs={12}>
                            <Divider />
                            <Grid container>
                                <Grid item xs={8}>
                                    <StepHandler />
                                </Grid>
                            </Grid>
                            <div className={'subtitle'}>{t('enterCompanyAddress')}</div>
                            <FormWrapper
                                className="form"
                                formRef={ref}
                                onSubmit={rootStore.nextStep}
                                isValid={ctx.isFormValid}>
                                <Grid container spacing={4} className="companyAddressForm">
                                    <Grid item xs={6}>
                                        <TextFieldWrapper
                                            name="companyName"
                                            type="text"
                                            label={t('companyName')}
                                            tooltipText={t('tooltips.companyName')}
                                            value={companyAddressForm.companyName}
                                            onChange={_handleChange}
                                            required
                                        />
                                    </Grid>
                                    <Grid item xs={6}>
                                        <TextFieldWrapper
                                            name="companyDomain"
                                            type="text"
                                            label={t('companyDomain')}
                                            tooltipText={t('tooltips.companyDomain')}
                                            value={companyAddressForm.companyDomain}
                                            onChange={_handleChange}
                                            required
                                        />
                                    </Grid>
                                    <Grid item xs={12} container spacing={2}>
                                        <Grid item xs={6}>
                                            <SelectWrapper
                                                name="country"
                                                label={t('country')}
                                                value={companyAddressForm.country}
                                                onChange={_handleChange}
                                                required>
                                                {countries.map((c: ICountry) => (
                                                    <MenuItem key={c.code} value={c.code}>
                                                        {c.name}
                                                    </MenuItem>
                                                ))}
                                            </SelectWrapper>
                                        </Grid>
                                    </Grid>
                                    <Grid item xs={6}>
                                        <TextFieldWrapper
                                            name="address"
                                            type="text"
                                            label={t('address')}
                                            value={companyAddressForm.address}
                                            onChange={_handleChange}
                                            disabled={!isCountryFilled}
                                            required
                                        />
                                    </Grid>
                                    <Grid item xs={6}>
                                        <TextFieldWrapper
                                            name="address2"
                                            type="text"
                                            label={t('address2')}
                                            value={companyAddressForm.address2}
                                            onChange={_handleChange}
                                            disabled={!isCountryFilled}
                                        />
                                    </Grid>
                                    <Grid item xs={6}>
                                        <TextFieldWrapper
                                            name="city"
                                            type="text"
                                            label={t('city')}
                                            tooltipText={t('tooltips.city')}
                                            value={companyAddressForm.city}
                                            onChange={_handleChange}
                                            disabled={!isCountryFilled}
                                            required
                                        />
                                    </Grid>
                                    <Grid item xs={3}>
                                        <SelectWrapper
                                            name="state"
                                            label={t(stateLabel)}
                                            value={companyAddressForm.state}
                                            onChange={_handleChange}
                                            disabled={!isCountryFilled}
                                            required>
                                            {states.map((s) => (
                                                <MenuItem key={s} value={s}>
                                                    {s}
                                                </MenuItem>
                                            ))}
                                        </SelectWrapper>
                                    </Grid>
                                    <Grid item xs={3}>
                                        <TextFieldWrapper
                                            name="zip"
                                            type="text"
                                            label={t(zipLabel)}
                                            value={companyAddressForm.zip}
                                            onChange={_handleChange}
                                            disabled={!isCountryFilled}
                                            required
                                        />
                                    </Grid>
                                </Grid>
                            </FormWrapper>
                        </Grid>
                        <Grid item xs={12} className={'bottomSection'}>
                            <Grid container spacing={4}>
                                <Grid item xs={2}>
                                    <ButtonWrapper
                                        startIcon={<ArrowBackIosIcon fontSize="small" />}
                                        variant="outlined"
                                        className={classes.backButton}
                                        onClick={rootStore.previousStep}>
                                        {t('common:back')}
                                    </ButtonWrapper>
                                </Grid>
                                <Grid item xs={8} />
                                <Grid item xs={2}>
                                    <ButtonWrapper
                                        className="saveButton"
                                        disabled={!ctx.isFormValid}
                                        onClick={rootStore.nextStep}>
                                        {t('common:next')}
                                    </ButtonWrapper>
                                </Grid>
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
            </DefaultContainer>
        </div>
    );
};

export default inject('AppStore', 'CertificateManagementStore')(observer(CreateCSRStep4));
