import CertificateManagementStore from 'components/certificate-management/CertificateManagementStore';
import { AppStoreProps } from 'components/migrate-member/types';

export interface ICreateCSRProps extends AppStoreProps {
    CertificateManagementStore?: CertificateManagementStore;
}

export interface ICompanyAdderss {
    companyName: string;
    companyDomain: string;
    country: string;
    address: string;
    address2: string;
    city: string;
    state: string;
    zip: string;
}

export interface ICompanyDetails {
    organizationUnit: string;
    ncaCountry: string;
    ncaLicenseNumber: string;
    companyNumber: string;
    subjectAlternativeNames: string;
}

export const UK = 'UK';
export const EU = 'EU';
export type TRegion = typeof UK | typeof EU;
export const CREATE = 'CREATE';
