import { Divider, FormControlLabel, Grid, Radio, RadioGroup } from '@material-ui/core';
import { inject, observer } from 'mobx-react';
import React from 'react';

import AppController from 'base/App.controller';
import StepHandler from 'components/certificate-management/create-certificate/StepHandler';
import Breadcrumbs from 'components/common/breadcrumbs/Breadcrumbs';
import ButtonWrapper from 'components/common/wrappers/ButtonWrapper';
import DefaultContainer from 'layout/DefaultContainer';

import { Itranslation } from 'types';
import { EU, ICreateCSRProps, TRegion, UK } from 'components/certificate-management/types';

const CreateCSRStep1: React.FC<ICreateCSRProps> = ({ AppStore, CertificateManagementStore }) => {
    const { t }: Itranslation = AppController.getTranslation(AppStore.scope, [
        'create-certificate',
        'common',
    ]);
    const { rootStore, region, setRegion } = CertificateManagementStore!.CreateCertificateStore;

    return (
        <div className={'createCSRStep1'}>
            <DefaultContainer>
                <Breadcrumbs
                    previousStep={rootStore.previousStep}
                    parentPath={t('parentTitle')}
                    currentPath={t('title')}
                />
                <Grid container spacing={4}>
                    <Grid container item xs={8}>
                        <Grid item xs={12}>
                            <Divider />
                            <div className="description">
                                <span>{t('csrDescription')}</span>
                            </div>
                            <Grid container>
                                <Grid item xs={8}>
                                    <StepHandler />
                                </Grid>
                            </Grid>
                            <div className={'subtitle'}>{t('chooseRegion')}</div>
                            <Grid container>
                                <RadioGroup
                                    row
                                    aria-label="region"
                                    name="region"
                                    value={region}
                                    onChange={(e) => setRegion(e.target.value as TRegion)}
                                    className={'radioGroup'}>
                                    <FormControlLabel
                                        value={UK}
                                        // TODO: (APE-3998) This is temporarily disabled.
                                        // Remove the 'disabled' prop once the API starts supporting UK flow.
                                        disabled
                                        control={<Radio />}
                                        label={t('uk')}
                                    />
                                    <FormControlLabel
                                        value={EU}
                                        control={<Radio />}
                                        label={t('eu')}
                                    />
                                </RadioGroup>
                            </Grid>
                        </Grid>
                        <Grid item xs={12} className={'bottomSection'}>
                            <Grid container spacing={4} justify="flex-end">
                                <Grid item xs={2}>
                                    <ButtonWrapper
                                        className="nextButton"
                                        onClick={rootStore.nextStep}>
                                        {t('common:next')}
                                    </ButtonWrapper>
                                </Grid>
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
            </DefaultContainer>
        </div>
    );
};

export default inject('AppStore', 'CertificateManagementStore')(observer(CreateCSRStep1));
