import {
    Divider,
    FormControlLabel,
    Grid,
    MenuItem,
    Radio,
    RadioGroup,
    Switch,
    Tooltip,
    Typography,
} from '@material-ui/core';
import { inject, observer } from 'mobx-react';
import React, { useEffect, useRef, useState } from 'react';

import AppController from 'base/App.controller';
import { AppStoreProps, Itranslation } from 'types';
import RenderKey from '../features/helpers/render-key/RenderKey';
import GenericErrorModal from 'components/common/modals/error/GenericError';
import GenericSuccessModal from 'components/common/modals/success/GenericSuccess';
import RichEditorModal from 'components/common/modals/rich-editor/RichEditorModal';
import ColorPicker from '../css/color-picker/ColorPicker';
import ButtonWrapper from 'components/common/wrappers/ButtonWrapper';
import { Preview } from 'components/common/wrappers/rich-editor';
import SelectWrapper from 'components/common/wrappers/SelectWrapper';
import TextFieldWrapper from 'components/common/wrappers/TextFieldWrapper';
import ConfigurationStore from 'components/configuration/ConfigurationStore';

// types
import { LegalTextTypes, SupportedLanguages } from './types';

// icons
import EditIcon from '@material-ui/icons/Edit';
import SettingsBackupRestoreSharpIcon from '@material-ui/icons/SettingsBackupRestoreSharp';
import StyledIcon from 'components/common/styled-icon/transparent-bg-on-hover/StyledIcon';

// helpers
import serialize from 'helpers/serialize-slate/serialize.js';

// constants
import { getLanguages } from 'constants/languages';
import {
    CUSTOM,
    LEGAL_TEXT_TYPES,
    NO_FOOTER_LOGO,
    TRUST_LOGOS,
    footerType,
} from 'constants/webAppV2';

// image
import successIcon from 'images/password-reset.svg';

export interface IWebAppV2CustomizationProps extends AppStoreProps {
    ConfigurationStore?: ConfigurationStore;
}

export const getSerializedInput = (value: any) => {
    let serializedInput = '';
    value.map((v: any) => (serializedInput += serialize(v)));
    serializedInput = serializedInput === '<br>' ? '' : serializedInput;
    return serializedInput;
};

const WebAppV2: React.FC<IWebAppV2CustomizationProps> = ({ ConfigurationStore, AppStore }) => {
    const { t }: Itranslation = AppController.getTranslation(AppStore?.scope, ['web-app-v2']);
    const {
        language,
        setLanguage,
        helpTextFields,
        getEditContentPlainText,
        restoreLegalTextToDefault,
        legalForm,
        handleBusinessNameChange,
        populateLegalTextsLoaders,
        handleTranslationChange,
        handleEditContent,
        flowCustomisationList,
        handleChangeFlowCustomisation,
        defaultColorOptions,
        colorOptions,
        handleLogoUpload,
        isTppLogoLightUploaded,
        isTppLogoDarkUploaded,
        isFooterLogoLightUploaded,
        isFooterLogoDarkUploaded,
        tppLogoLight,
        tppLogoDark,
        footerLogoLight,
        footerLogoDark,
        footerType,
        setFooterType,
        handleLightColorChange,
        handleDarkColorChange,
        handleSave,
        showLightTppLogoError,
        showDarkTppLogoError,
        showLightFooterLogoError,
        showDarkFooterLogoError,
        handleResetLogo,
        fetchAndSaveConfigurations,
        enableSave,
        sessionDetailsSettingsList,
        handleEditSessionDetailsSettings,
        loadingSaveButton,
        GenericSuccessStore,
        GenericErrorStore,
        customLogosNotUploaded,
        isLegalTextEditable,
        resetToDefaults,
    } = ConfigurationStore!.WebAppV2Store;

    useEffect(() => {
        populateLegalTextsLoaders();
        fetchAndSaveConfigurations();
    }, []);

    const _handleLanguageChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        setLanguage(e.target.value as SupportedLanguages);
    };
    const sessionDetailsSettings = [
        'showRemittanceInformationSecondary',
        'showCreditorAccountName',
        'showCreditorAccountIdentifier',
        'showCreditorBankIdentifier',
    ];
    const transactionStatusDetailsSettings = [
        'showDebtorAccountIdentifier',
        'showDebtorBankIdentifier',
    ];
    const [openLegalTextEditor, setOpenLegalTextEditor] = useState(false);
    const [openContentEditor, setOpenContentEditor] = useState(false);
    const [current, setCurrent] = useState('');
    const [isResetTodefaultLoading, setIsResetToDefaultsLoading] = useState(false);
    const fileInputRefs = useRef<{ [key: string]: HTMLInputElement }>({});

    const openRichEditorModal = (legalTextKey: React.Key) => {
        setCurrent(legalTextKey.toString());
        if (legalTextKey === 'editTOS' || legalTextKey === 'editPP') {
            setOpenContentEditor(true);
        } else {
            setOpenLegalTextEditor(true);
        }
    };

    const onClose = () => {
        setOpenContentEditor(false);
        setOpenLegalTextEditor(false);
    };

    const _handleChangeLegalText = (value: any) => {
        const serializedInput = getSerializedInput(value);
        if (current === 'editTOS' || current === 'editPP') {
            handleEditContent(current, serializedInput);
        } else {
            handleTranslationChange(current as LegalTextTypes, serializedInput);
        }
    };

    const _handleResetToDefaults = () => {
        setIsResetToDefaultsLoading(true);
        resetToDefaults();
        Object.values(fileInputRefs.current).forEach((ref) => (ref.value = ''));
        setIsResetToDefaultsLoading(false);
    };

    const _handleResetLogo = (name: 'tppDark' | 'tppLight') => {
        handleResetLogo(name);
        fileInputRefs.current[name].value = '';
    };

    const formatHtml = (previewText: string) => {
        return `<div class='preview-legal-text'>${previewText}</div>`;
    };

    const displayToggleSwitch = (list: string[]) => {
        return list.map((key) => (
            <Grid container item xs={12} key={key} className={key}>
                <Grid item xs={4} className={'sessionDetailField'}>
                    {t(key)}
                </Grid>
                <Grid item xs={1} />
                <Grid item xs={1} className={'sessionDetailField'}>
                    <Switch
                        className="IOSSwitch-root"
                        name={key}
                        checked={sessionDetailsSettingsList[key]}
                        classes={{
                            switchBase: 'IOSSwitch-switchBase',
                            thumb: 'IOSSwitch-thumb',
                            track: 'IOSSwitch-track',
                        }}
                        onChange={handleEditSessionDetailsSettings}
                        inputProps={{ 'aria-label': 'ios switch' }}
                    />
                </Grid>
                <Grid item xs={2} className="sessionDetailField">
                    {sessionDetailsSettingsList[key] === true
                        ? t('toggleChecked')
                        : t('toggleUnchecked')}
                </Grid>
                <Grid />
            </Grid>
        ));
    };

    const footerCustomisationBlock = () => {
        return (
            <Grid item xs={12} lg={9} className="FooterCustomisation">
                <h2>{t('footerCustomisation')}</h2>
                <Grid container item className={'customfields'}>
                    <RadioGroup
                        aria-label="footer type"
                        name="footerType"
                        value={footerType}
                        onChange={(e) => setFooterType(e.target.value as footerType)}
                        className={'radioGroup'}>
                        <FormControlLabel
                            value={TRUST_LOGOS}
                            control={<Radio />}
                            label={
                                <Typography>
                                    {t('trustLogosRadio.text')}{' '}
                                    <span className="subTextMessage">
                                        {t('trustLogosRadio.subText')}
                                    </span>
                                </Typography>
                            }
                        />
                        <FormControlLabel
                            value={CUSTOM}
                            control={<Radio />}
                            label={
                                <span className="customFooterRadio">
                                    <Typography>{t('customFooterRadio.text')}</Typography>
                                    <Typography>
                                        <span className="subTextMessage">
                                            {t('customFooterRadio.subText')}
                                        </span>
                                    </Typography>
                                </span>
                            }
                        />
                        {footerType === CUSTOM && (
                            <div>
                                <Grid container item xs={12}>
                                    <Grid container item xs={9}>
                                        <Grid item xs={1} />
                                        <Grid
                                            item
                                            xs={5}
                                            className={showLightFooterLogoError ? 'LogoError' : ''}>
                                            <span className="modeIndicator">{t('lightMode')}</span>
                                            <Grid item className={'buttonContainer'}>
                                                <TextFieldWrapper
                                                    name="footerLogoLight"
                                                    type="file"
                                                    inputProps={{
                                                        accept: ['.png', '.jpg', '.jpeg'],
                                                    }}
                                                    onChange={handleLogoUpload}
                                                    hideOptional
                                                />
                                            </Grid>
                                            {showLightFooterLogoError && (
                                                <Grid item xs={12} className="LogoSizeError">
                                                    {t('uploadFailed')}
                                                    <br />
                                                    {t('uploadErrorMessage')}
                                                </Grid>
                                            )}
                                            {isFooterLogoLightUploaded && (
                                                <Grid item xs={12} className="customLogoLight">
                                                    <img
                                                        src={footerLogoLight.data}
                                                        alt="Footer logo for light theme"
                                                        className={'logo'}
                                                    />
                                                </Grid>
                                            )}
                                        </Grid>
                                        <Grid item xs={1} />
                                        <Grid
                                            item
                                            xs={5}
                                            className={showDarkFooterLogoError ? 'LogoError' : ''}>
                                            <span className="modeIndicator">{t('darkMode')}</span>
                                            <Grid item className={'buttonContainer'}>
                                                <TextFieldWrapper
                                                    name="footerLogoDark"
                                                    type="file"
                                                    inputProps={{
                                                        accept: ['.png', '.jpg', '.jpeg'],
                                                    }}
                                                    onChange={handleLogoUpload}
                                                    hideOptional
                                                />
                                            </Grid>
                                            {showDarkFooterLogoError && (
                                                <Grid item xs={12} className="LogoSizeError">
                                                    {t('uploadFailed')}
                                                    <br />
                                                    {t('uploadErrorMessage')}
                                                </Grid>
                                            )}
                                            {isFooterLogoDarkUploaded && (
                                                <Grid item xs={12} className="customLogoDark">
                                                    <img
                                                        src={footerLogoDark.data}
                                                        alt="Footer logo for dark theme"
                                                        className={'logo'}
                                                    />
                                                </Grid>
                                            )}
                                        </Grid>
                                    </Grid>
                                </Grid>
                            </div>
                        )}
                        <FormControlLabel
                            value={NO_FOOTER_LOGO}
                            control={<Radio />}
                            label={
                                <span className="noFooterRadio">
                                    <Typography>{t('noFooterRadio.text')}</Typography>
                                </span>
                            }
                        />
                    </RadioGroup>
                </Grid>
            </Grid>
        );
    };

    const flowCustomisationBlock = () => {
        return (
            <Grid item xs={12} lg={9} className="FlowCustomisation">
                <h2>{t('flowCustomisation')}</h2>
                <Grid container item xs={12} className={'customfields'}>
                    {helpTextFields.map((key: any) => (
                        <Grid container className={'fields'} key={key} item>
                            <RenderKey
                                obj={flowCustomisationList}
                                name={key}
                                isCheck
                                helpText={t(`helpText.${key}`)}
                                onCheck={handleChangeFlowCustomisation}
                                label={t(key)}
                            />
                            {key === 'enableTransactionStatusPage' &&
                                displayToggleSwitch(transactionStatusDetailsSettings)}
                        </Grid>
                    ))}
                </Grid>
            </Grid>
        );
    };

    const legalTextsBlock = () => {
        return (
            <Grid container item xs={12} className="LegalTexts">
                <h2>{t('legalText')}</h2>
                <Grid container item xs={12} spacing={4}>
                    <Grid item xs={4}>
                        <SelectWrapper
                            name="language"
                            label={t('language')}
                            value={language}
                            onChange={_handleLanguageChange}
                            required>
                            {getLanguages.map((c) => (
                                <MenuItem key={c.code} value={c.code}>
                                    {c.name}
                                </MenuItem>
                            ))}
                        </SelectWrapper>
                    </Grid>
                    <Grid container item spacing={4} direction="column">
                        <Grid container item spacing={4}>
                            <Grid item xs={2}>
                                <TextFieldWrapper
                                    name="businessName"
                                    label={t('businessName')}
                                    value={legalForm.businessName}
                                    onChange={handleBusinessNameChange}
                                    required
                                />
                            </Grid>
                            <Grid item xs={2}>
                                <TextFieldWrapper
                                    name="displayName"
                                    label={t('displayName')}
                                    value={legalForm.displayName}
                                    hideOptional
                                    disabled
                                />
                            </Grid>
                        </Grid>
                        <Grid item xs={2}>
                            <TextFieldWrapper
                                name="expirationDate"
                                label={t('expirationDate')}
                                value={legalForm.expirationDate}
                                hideOptional
                                disabled
                            />
                        </Grid>
                    </Grid>
                    <Grid item container spacing={2}>
                        {LEGAL_TEXT_TYPES.map((legalTextKey) => (
                            <Grid container key={legalTextKey} item xs={12}>
                                <Grid item xs={2} className={'legalTextKey'}>
                                    {t(`${legalTextKey.toString()}`)}
                                </Grid>
                                <Grid container item>
                                    <Grid className={'editor'} item>
                                        <Preview
                                            html={`<div class='preview-legal-text'>${legalForm[legalTextKey][language]}</div>`}
                                            isOnlyDisplay
                                        />
                                    </Grid>
                                    <Grid item>
                                        <Grid container direction="column">
                                            <StyledIcon
                                                className={`edit-${legalTextKey}-text`}
                                                onClick={() => openRichEditorModal(legalTextKey)}
                                                disabled={!isLegalTextEditable}>
                                                <EditIcon />
                                            </StyledIcon>
                                            <Tooltip title={t('restoreDefaultLegal') as string}>
                                                <StyledIcon
                                                    className={'restoreDefaultConsentIcon'}
                                                    onClick={() => {
                                                        restoreLegalTextToDefault(legalTextKey);
                                                    }}
                                                    disabled={!isLegalTextEditable}
                                                    aria-label={'Restore Defaults'}>
                                                    <SettingsBackupRestoreSharpIcon />
                                                </StyledIcon>
                                            </Tooltip>
                                        </Grid>
                                    </Grid>
                                </Grid>
                            </Grid>
                        ))}
                    </Grid>
                </Grid>
            </Grid>
        );
    };

    const editLegalTextContent = () => {
        return (
            <Grid container item xs={9} className="EditLegalContent">
                <Grid item xs={12}>
                    <h2>{t('editLegalContent')}</h2>
                </Grid>
                <Grid item xs={12} className="editLegalText">
                    {t('editTOS')}
                    <StyledIcon
                        onClick={() => openRichEditorModal('editTOS')}
                        className={'icon edit editTOS'}
                        disabled={!isLegalTextEditable}>
                        <EditIcon />
                    </StyledIcon>
                </Grid>
                <Grid item xs={12} className="editLegalText">
                    {t('editPP')}
                    <StyledIcon
                        onClick={() => openRichEditorModal('editPP')}
                        className={'icon edit editPP'}
                        disabled={!isLegalTextEditable}>
                        <EditIcon />
                    </StyledIcon>
                </Grid>
            </Grid>
        );
    };

    const uiCustomisationBlock = () => {
        return (
            <Grid item container xs={9}>
                <Grid item xs={12} className="UI Customisation">
                    <h2>{t('uiCustomisation')}</h2>
                    <RenderKey
                        obj={flowCustomisationList}
                        name="enableAutoMode"
                        isCheck
                        helpText={t('helpText.enableAutoMode')}
                        onCheck={handleChangeFlowCustomisation}
                        label={t('enableAutoMode')}
                    />
                </Grid>
                <Grid container item xs={12} spacing={1} className="previewHeads">
                    <Grid item xs={3} />
                    <Grid container item xs={4} spacing={1}>
                        <Grid item xs={6} className="modeIndicator">
                            {t('lightMode')}
                        </Grid>
                        <Grid item xs={6} className="quickPreview">
                            {t('quickPreview')}
                        </Grid>
                    </Grid>
                    <Grid item xs={1} />
                    <Grid container item xs={4} spacing={1}>
                        <Grid item xs={6} className="modeIndicator">
                            {t('darkMode')}
                        </Grid>
                        <Grid item xs={6} className="quickPreview">
                            {t('quickPreview')}
                        </Grid>
                    </Grid>
                </Grid>
                <Grid container item xs={12} className={'colors'}>
                    {Object.keys(colorOptions).map((key) => (
                        <Grid container item xs={12} spacing={1} key={key} className="uiRow">
                            <Grid item xs={3} className={'uiKey'}>
                                <span>{t(`colorOptions.${key}`)}</span>
                            </Grid>
                            <Grid container item xs={4} spacing={1}>
                                <Grid item xs={6} className={`${key}-light`}>
                                    <ColorPicker
                                        name={key}
                                        value={colorOptions[key].light.value}
                                        placeholder={
                                            defaultColorOptions[key as keyof typeof colorOptions]
                                                .light.value
                                        }
                                        onChange={handleLightColorChange}
                                        Store={colorOptions[key].light.colorPickerStore}
                                        tooltipText={t(`tooltip.${key}`)}
                                    />
                                </Grid>
                                {key === 'accentColor' || key === 'secondaryAccentColor' ? (
                                    <Grid
                                        item
                                        xs={6}
                                        className="contrastTest"
                                        style={{ backgroundColor: 'white' }}>
                                        <span>Contrast test&nbsp;</span>
                                        <span
                                            style={{
                                                color:
                                                    key === 'accentColor'
                                                        ? colorOptions.accentColor.light.value
                                                        : colorOptions.secondaryAccentColor.light
                                                              .value,
                                            }}>
                                            {key === 'accentColor'
                                                ? 'Primary color'
                                                : 'Secondary color'}
                                        </span>
                                    </Grid>
                                ) : (
                                    <Grid item xs={6} />
                                )}
                            </Grid>
                            <Grid item xs={1} />
                            <Grid container item xs={4} spacing={1}>
                                <Grid item xs={6} className={`${key}-dark`}>
                                    <ColorPicker
                                        name={key}
                                        value={colorOptions[key].dark.value}
                                        placeholder={defaultColorOptions[key].light.value}
                                        onChange={handleDarkColorChange}
                                        Store={colorOptions[key].dark.colorPickerStore}
                                        tooltipText={t(`tooltip.${key}`)}
                                    />
                                </Grid>
                                {key === 'accentColor' || key === 'secondaryAccentColor' ? (
                                    <Grid
                                        item
                                        xs={6}
                                        className="contrastTest"
                                        style={{ backgroundColor: 'black' }}>
                                        <span>Contrast test&nbsp;</span>
                                        <span
                                            style={{
                                                color:
                                                    key === 'accentColor'
                                                        ? colorOptions.accentColor.dark.value
                                                        : colorOptions.secondaryAccentColor.dark
                                                              .value,
                                            }}>
                                            {key === 'accentColor'
                                                ? 'Primary color'
                                                : 'Secondary color'}
                                        </span>
                                    </Grid>
                                ) : (
                                    <Grid item xs={6} />
                                )}
                            </Grid>
                        </Grid>
                    ))}
                </Grid>
                <Grid container item xs={12} spacing={1} className="uiRow">
                    <Grid item xs={3} className={'uiKey'}>
                        <span>{t('logo')}</span>
                        <Grid item xs={8}>
                            <span className="subTextMessage">{t('tppLogoSubText')}</span>
                        </Grid>
                    </Grid>
                    <Grid item xs={4} className={showLightTppLogoError ? 'LogoError' : ''}>
                        <Grid item className={'buttonContainer'}>
                            <TextFieldWrapper
                                name="tppLogoLight"
                                type="file"
                                inputRef={(el) => (fileInputRefs.current['tppLight'] = el)}
                                inputProps={{
                                    accept: ['.png', '.jpg', '.jpeg'],
                                }}
                                onChange={handleLogoUpload}
                                hideOptional
                            />
                            <Tooltip title={t('resetLogo')!}>
                                <StyledIcon
                                    className={'resetLogo resetLightTppLogo'}
                                    onClick={() => _handleResetLogo('tppLight')}
                                    aria-label={'Resets Logo'}>
                                    <SettingsBackupRestoreSharpIcon />
                                </StyledIcon>
                            </Tooltip>
                        </Grid>
                        {showLightTppLogoError && (
                            <Grid item xs={12} className="LogoSizeError">
                                {t('uploadFailed')}
                                <br />
                                {t('uploadErrorMessage')}
                            </Grid>
                        )}
                        {isTppLogoLightUploaded && (
                            <Grid item xs={12}>
                                <img
                                    src={tppLogoLight.data}
                                    alt="TPP logo for light theme"
                                    className={'logo'}
                                />
                            </Grid>
                        )}
                    </Grid>
                    <Grid item xs={1} />
                    <Grid item xs={4} className={showDarkTppLogoError ? 'LogoError' : ''}>
                        <Grid item className={'buttonContainer'}>
                            <TextFieldWrapper
                                name="tppLogoDark"
                                type="file"
                                inputRef={(el) => (fileInputRefs.current['tppDark'] = el)}
                                onChange={handleLogoUpload}
                                inputProps={{
                                    accept: ['.png', '.jpg', '.jpeg'],
                                }}
                                hideOptional
                            />
                            <Tooltip title={t('resetLogo')!}>
                                <StyledIcon
                                    className={'resetLogo resetDarkTppLogo'}
                                    onClick={() => _handleResetLogo('tppDark')}
                                    aria-label={'Resets Logo'}>
                                    <SettingsBackupRestoreSharpIcon />
                                </StyledIcon>
                            </Tooltip>
                        </Grid>
                        {showDarkTppLogoError && (
                            <Grid item xs={12} className="LogoSizeError">
                                {t('uploadFailed')}
                                <br />
                                {t('uploadErrorMessage')}
                            </Grid>
                        )}
                        {isTppLogoDarkUploaded && (
                            <Grid item xs={12}>
                                <img
                                    src={tppLogoDark.data}
                                    alt="TPP logo for dark theme"
                                    className={'logo'}
                                />
                            </Grid>
                        )}
                    </Grid>
                </Grid>
            </Grid>
        );
    };

    const finalizeChanges = () => {
        return (
            <Grid container item xs={9} spacing={2} className="finalButtonBlock">
                <Grid item lg={3} md={3} sm={12}>
                    <ButtonWrapper
                        onClick={_handleResetToDefaults}
                        className={'resetToDefaultsButton'}
                        loading={isResetTodefaultLoading}>
                        {t('resetToDefaults')}
                    </ButtonWrapper>
                </Grid>
                {/* To do: Implementation of Preview Button
                <Grid item lg={3} md={3} sm={12}>
                    <ButtonWrapper
                        onClick={() => {}}
                        className={'previewButton'}
                        disabled={false}
                        loading={false}>
                        {t('preview')}
                    </ButtonWrapper>
                </Grid> */}
                <Grid item lg={3} md={3} sm={12}>
                    <ButtonWrapper
                        onClick={handleSave}
                        className={'saveButton'}
                        disabled={!enableSave}
                        loading={loadingSaveButton}>
                        {t('save')}
                    </ButtonWrapper>
                    {customLogosNotUploaded && (
                        <span className="LogoSizeError"> {t('footerLogosMissing')}</span>
                    )}
                </Grid>
            </Grid>
        );
    };

    const legalTextEditorModalBlock = () => {
        return (
            <RichEditorModal
                open={openLegalTextEditor}
                onClose={() => onClose()}
                plainText={legalForm[current as LegalTextTypes]?.[language]}
                disabled={[
                    'heading-one',
                    'heading-two',
                    'numbered-list',
                    'bulleted-list',
                    'block-quote',
                    'code',
                    'image',
                    'indent-increase',
                    'indent-decrease',
                    'left',
                    'right',
                    'center',
                    'link',
                ]}
                buttonText={t('confirm')}
                onConfirm={_handleChangeLegalText}
                title={t(`modify${current.split('.')[0].toUpperCase()}`)}
                formatHtml={formatHtml}
            />
        );
    };

    const sessionDetailsSettingsBlock = () => {
        return (
            <Grid container item xs={9} className="editSessionDetails">
                <Grid item xs={12}>
                    <h2>{t('editSessionDetails')}</h2>
                    <p>{t('editSessionDetailsHelpText')}</p>
                </Grid>
                {displayToggleSwitch(sessionDetailsSettings)}
            </Grid>
        );
    };

    const legalContentEditorModalBlock = () => {
        return (
            <RichEditorModal
                open={openContentEditor}
                onClose={() => onClose()}
                plainText={getEditContentPlainText(current)}
                disabled={[
                    'heading-one',
                    'heading-two',
                    'numbered-list',
                    'bulleted-list',
                    'block-quote',
                    'code',
                    'image',
                    'indent-increase',
                    'indent-decrease',
                    'linkV2',
                ]}
                buttonText={t('confirm')}
                onConfirm={_handleChangeLegalText}
                title={t(`${current}`)}
                formatHtml={formatHtml}
            />
        );
    };

    return (
        <div className={'WebAppV2'}>
            <Grid container item xs={12}>
                {uiCustomisationBlock()}
                {sessionDetailsSettingsBlock()}
                {footerCustomisationBlock()}
                {flowCustomisationBlock()}
                <Grid className={'divider'} item xs={12} lg={9}>
                    <Divider />
                </Grid>
                {legalTextsBlock()}
                {editLegalTextContent()}
                {finalizeChanges()}
            </Grid>
            {openLegalTextEditor && legalTextEditorModalBlock()}
            {openContentEditor && legalContentEditorModalBlock()}
            <GenericErrorModal Store={GenericErrorStore} />
            <GenericSuccessModal
                Store={GenericSuccessStore}
                title={t('modalTitle')}
                subtitle={t('modalDescription')}
                buttonText={t('common:okay')}
                icon={successIcon}
            />
        </div>
    );
};

export default inject('AppStore', 'ConfigurationStore')(observer(WebAppV2));
